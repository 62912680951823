import React, { Fragment, useEffect, useState } from "react";
import AppHeader from "../../../../Layout/AppHeader";
import AppFooter from "../../../../Layout/AppFooter";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import "../AndroidSettings/AndroidSettings.scss";
import { useParams } from "react-router-dom";
import { callAccedoAPI } from "../../../../axios/accedoApi";

function DiagnosisCheck() {
  const params = useParams();

  const [loader, setLoader] = useState(true);
  const [diagnosticData, setDiagnosticData] = useState({});

  useEffect(() => {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}/KPI/diagnosticCheck/${
        params?.crid
      }`,
      "GET"
    )
      .then((response) => {
        setDiagnosticData(response);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  }, []);

  const data = {
    liveTvStatus: {
      signalAvailable: true,
      strength: "string",
      quality: "string",
      mer: "string",
    },
    cpuStats: {
      tempMax: 12,
      tempMin: 12,
      tempMean: 12,
      tempVar: 123,
      max: 12,
      min: 12,
      mean: 12,
      var: 12,
    },
    storage: {
      total: 12,
      available: 12,
    },
    blockAppStatus: {
      account: "string",
      device: "string",
      binge: "string",
    },
  };

  return (
    <Fragment>
      <AppHeader hideHamberger={true} />
      <div className="app-main">
        {/* <AppSidebar/> */}
        <div className="app-main__outer" style={{ width: "95%" }}>
          <div className="app-main__inner">
            <div className="mt-3">
              <h4>Diagnostic Data</h4>
              {loader ? (
                <div className="customLoader">
                  <CircularProgress />
                </div>
              ) : (
                <div className="installedapps-acordian">
                  {Object.entries(data).map(([key, value]) => (
                    <Accordion key={key}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="accordion-title Roboto-Medium">
                          {key
                            .match(/([A-Z]?[^A-Z]*)/g)
                            .slice(0, -1)
                            .join(" ")}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails className="accordian-details p-4 ml-3 mr-3">
                        <div className="list-style android-font row">
                          {Object.keys(value).map((subKey) => (
                            <div className="col-4 mb-2" key={subKey}>
                              <span className="accordiantitle accordiansubtitles">
                                {subKey
                                  .match(/([A-Z]?[^A-Z]*)/g)
                                  .slice(0, -1)
                                  .join(" ")}
                              </span>{" "}
                              :{" "}
                              {Array.isArray(value[subKey])
                                ? value[subKey].map((item, index) => (
                                    <p className="wordbreakall" key={index}>
                                      {item}
                                    </p>
                                  ))
                                : `${value[subKey]}`}
                            </div>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              )}
            </div>
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  );
}

export default DiagnosisCheck;
