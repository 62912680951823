import * as ACTION from './actionTypes';
import {callAPI} from "../../axios"
import { callAccedoAPI } from '../../axios/accedoApi';

const EntitlementRequest = () => {
    return {
        type: ACTION.GET_ENTITLEMENT_REQUEST
    }
}

const EntitlementSuccessDth = (userToken) => {
    return {
        type: ACTION.GET_ENTITLEMENT_SUCCESS_DTH,
        payload: userToken,
    }
}

const EntitlementSuccessBinge = (userToken) => {
    return {
        type: ACTION.GET_ENTITLEMENT_SUCCESS_BINGE,
        payload: userToken,
    }
}

const EntitlementSuccessOtt = (userToken) => {
    return {
        type: ACTION.GET_ENTITLEMENT_SUCCESS_OTT,
        payload: userToken,
    }
}

const EntitlementSuccessBoxOtt = (userToken) => {
    return {
        type: ACTION.GET_ENTITLEMENT_SUCCESS_BOX_OTT,
        payload: userToken,
    }
}

const EntitlementFailure = (errorMsg) => {
    return {
        type: ACTION.GET_ENTITLEMENT_FAILURE,
        payload: errorMsg,
    }
}

//For Accedo Entitlement API

const getEntitlementAccedoRequest = () => {
    return {
        type: ACTION.GET_ENTITLEMENTS_ACCEDO_REQUEST
    }
}

const getEntitlementAccedoSuccess = (apiData) => {
    return {
        type: ACTION.GET_ENTITLEMENTS_ACCEDO_SUCCESS,
        payload: apiData,
    }
}

const getEntitlementAccedoFailure = (errorMsg) => {
    return {
        type: ACTION.GET_ENTITLEMENTS_ACCEDO_FAILURE,
        payload: errorMsg,
    }
}

export const entitlementsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.ENTITLEMENTS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const entitlementsFetchNowControl = (payload)=>{
    return {
        type: ACTION.ENTITLEMENTS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const entitlementsFetchNowStatus = (payload) => {
    return {
        type: ACTION.ENTITLEMENTS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const entitlementFetchNowReset = (payload) => {
    return {
        type: ACTION.ENTITLEMENTS_FETCH_NOW_RESET,
        payload: payload,
    }
}

export const entitlementWebhookTriggered = (payload) => {
    return {
        type: ACTION.ENTITLEMENTS_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getEntitlementAccedoData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/entitlements/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getEntitlementAccedoRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getEntitlementAccedoSuccess(res));
        })
        .catch(err => {
            dispatch(getEntitlementAccedoFailure(new Date().getTime()));
        });;
    };
};

export const postFetchNowEntitlementControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowEntitlementsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(entitlementsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(entitlementsFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getEntitlementAccedoFailure(new Date().getTime()));
        });
    }; 
};

export const EntitlementActions = (sid, platform ,cdsn) => {
    return async function(dispatch) {
        dispatch(EntitlementRequest());
            const apiTtaPath = `/entitlement-detail/${sid}?requestType=${platform}`;
            // const apiTtnRefresh = `/entitlement-refresh/${sid}?requestType=${platform}&vcNumber=${cdsn}`;
            // callAPI(apiTtnRefresh,"GET").then(res => {
            //     console.log(res)
                callAPI(apiTtaPath,"GET").then(response => {
                    // console.log(res)
                    if(platform==="DTH"){
                        dispatch(EntitlementSuccessDth(response));
                    }
                    else if(platform==="BINGE"){
                        dispatch(EntitlementSuccessBinge(response));
                    }
                    else if(platform==="OTT"){
                        dispatch(EntitlementSuccessOtt(response));
                    }
                    else if(platform==="BOX_OTT"){
                        dispatch(EntitlementSuccessBoxOtt(response));
                    }
                })
            // })
        .catch(err => {
            dispatch(EntitlementFailure(err.message));
        });;
    };
};

 