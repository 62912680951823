export const MainNav = [
    {
        icon: "pe-7s-browser" ,
        label: 'Dashboard',
        to: '/dashboards/home',
    },
    
];

export const TotalDevicesNav = [
    {
        icon: "lnr-pilcrow" ,
        label: 'Total Devices Across India',
        to: '/total-devices-across-india/details',
    },
    
];

// export const UserManagementNav = [
//     {
//         icon: "pe-7s-users",
//         label: 'User Management',
//         content: [
//             {label: 'Users', to: '/UserManagement/userlist', permission: isValidRole("MENU_USERS")}
//         ],
//     },
    
// ];

// const userList = (isValidRole("MENU_USERS"))&&{label: 'Users', to: '/UserManagement/userlist'}
// const createUser = (isValidRole("MENU_USER_CREATE"))&&{label: 'Create User', to: '/UserManagement/create-user'}
// const userRoles = (isValidRole("MENU_ROLES"))&&{label: 'Roles', to: '/UserManagement/roles'}
// const rolesAndPermissions = (isValidRole("MENU_ROLES_AND_PERMISSIONS"))&&{label: 'Roles And Permissions', to: '/UserManagement/roles-permissions'}
// const userPrivileges = (isValidRole("MENU_USER_PRIVILEGES"))&&{label: 'User Privileges', to: '/UserManagement/user-privileges'}

// if(isValidRole("MENU_USERS")){
//     UserManagementNav[0]?.content?.push({label: 'Users', to: '/UserManagement/userlist'})
// }
// if(isValidRole("MENU_USER_CREATE")){
//     UserManagementNav[0]?.content?.push({label: 'Create User', to: '/UserManagement/create-user'})
// }
// if(isValidRole("MENU_ROLES")){
//     UserManagementNav[0]?.content?.push({label: 'Roles', to: '/UserManagement/roles'})
// }
// if(isValidRole("MENU_ROLES_AND_PERMISSIONS")){
//     UserManagementNav[0]?.content?.push({label: 'Roles And Permissions', to: '/UserManagement/roles-permissions'})
// }
// if(isValidRole("MENU_USER_PRIVILEGES")){
//     UserManagementNav[0]?.content?.push({label: 'User Privileges', to: '/UserManagement/user-privileges'})
// }
export const SIDCDSNLevels = [
    {
        icon: 'lnr-indent-increase',
        label: 'SID/CDSN Levels Details',
        to: '/SIDCDSNLevels',
    },
    
];

// const totalNoOfStb = (isValidRole("MENU_TOTAL_STB"))&&{label: 'Total No. of STB', to: '/EventsCaptured/total-stb'}
// const totalLauncherCrash = (isValidRole("TOTAL_LAUNCHER_CRASH"))&&{label: 'Total Launcher Crash', to: '/EventsCaptured/launcher-crash'}
// const totalFirmwareVersion = (isValidRole("MENU_FIRMWARE_VERSION"))&&{label: 'Total Firmware Version', to: '/EventsCaptured/firmware-version'}
// const totalAppVersion = (isValidRole("MENU_TOTAL_APP_VERSION"))&&{label: 'Total App Version', to: '/EventsCaptured/app-version'}
// const averageBatteryStatus = (isValidRole("MENU_BATTERY_STATUS"))&&{label: 'Average Battery Status', to: '/EventsCaptured/battery-status'}
// const averageNetworkSpeed = (isValidRole("MENU_AVERAGE_NETWORK_SPEED"))&&{label: 'Average Network Speed', to: '/EventsCaptured/network-speed'}
// const rcuKeyCodes = (isValidRole("MENU_RCU_KEY_CODES"))&&{label: 'RCU Key Codes', to: '/EventsCaptured/rcu-key-codes'}

export const EventsCapturedNav = [
    {
        icon: 'lnr-calendar-full',
        label: 'Events Captured',
        // content: [],
        to:'/EventsCaptured/events-captured'
    },
    
];

// if(totalNoOfStb){
//     EventsCapturedNav[0]?.content?.push(totalNoOfStb)
// }
// if(totalLauncherCrash){
//     EventsCapturedNav[0]?.content?.push(totalLauncherCrash)
// }
// if(totalFirmwareVersion){
//     EventsCapturedNav[0]?.content?.push(totalFirmwareVersion)
// }
// if(totalAppVersion){
//     EventsCapturedNav[0]?.content?.push(totalAppVersion)
// }
// if(averageBatteryStatus){
//     EventsCapturedNav[0]?.content?.push(averageBatteryStatus)
// }
// if(averageNetworkSpeed){
//     EventsCapturedNav[0]?.content?.push(averageNetworkSpeed)
// }
// if(rcuKeyCodes){
//     EventsCapturedNav[0]?.content?.push(rcuKeyCodes)
// }

// const stbFailureEvents = (isValidRole("MENU_STB_FAILURE_EVENTS"))&& {label: 'STB Failure Events', to: '/Dashboards/SystemErrorsIndiaLevel'}
// const systemDefinedThreshold = (isValidRole("MENU_DEVIATION_PERCENTAGE"))&&{label: 'Deviation Percentage', to: '/Dashboards/SystemDefinedThreshold'}
// const blockedApps = {label: 'Blocked Apps', to: '/Dashboards/SystemErrorsIndiaLevel'}
// export const SystemFailuresNav = [
    // {
    //     icon: 'pe-7s-info',
    //     label: 'System Failures',
    //     content: [],
    // },
    
// ];

// if(stbFailureEvents){
//     SystemFailuresNav[0]?.content?.push(stbFailureEvents)
// }
// if(systemDefinedThreshold){
//     SystemFailuresNav[0]?.content?.push(systemDefinedThreshold)
// }
// if(blockedApps){
//     SystemFailuresNav[0]?.content?.push(blockedApps)
// }

export const BlockedAppsNav = [
    {
        icon: 'pe-7s-blocked',
        label: 'Blocked Applications',
        // content: [],
        to:'/blocked-applications/details'
    },
    
];

export const RemoteWizard = [
    {
        icon: 'pe-7s-remotewizard',
        label: 'Remote Wizard',
        // content: [],
        to:'/wizard/wizard-list'
    },
    
];

export const GlobalEvents = [
    {
        icon: 'pe-7s-globalevent',
        label: 'Global Control Events',
        // content: [],
        to:'/global-events/global-events-list'
    },
    
];

export const ComponentsNav = [
    {
        icon: 'pe-7s-diamond',
        label: 'Elements',
        content: [
            {
                label: 'Standard Buttons',
                to: '/elements/buttons-standard',
            },
            {
                label: 'Dropdowns',
                to: '/elements/dropdowns',

            },
            {
                label: 'Icons',
                to: '/elements/icons',
            },
            {
                label: 'Badges',
                to: '/elements/badges-labels',
            },
            {
                label: 'Cards',
                to: '/elements/cards',
            },
            {
                label: 'List Groups',
                to: '/elements/list-group',
            },
            {
                label: 'Navigation Menus',
                to: '/elements/navigation',
            },
            {
                label: 'Utilities',
                to: '#/elements/utilities',
            },
        ],
    },
    {
        icon: 'pe-7s-car',
        label: 'Components',
        content: [
            {
                label: 'Tabs',
                to: '/components/tabs',
            },
            {
                label: 'Notifications',
                to: '/components/notifications',
            },
            {
                label: 'Modals',
                to: '/components/modals',
            },
            {
                label: 'Progress Bar',
                to: '/components/progress-bar',
            },
            {
                label: 'Tooltips & Popovers',
                to: '/components/tooltips-popovers',
            },
            {
                label: 'Carousel',
                to: '/components/carousel',
            },
            {
                label: 'Maps',
                to: '/components/maps',
            },
        ],
    }
];
export const ChartsNav = [
    {
        icon: 'pe-7s-graph2',
        label: 'ChartJS',
        to: '/charts/chartjs',
    },
];

export const AppVersionNav = [
    {
        icon: 'pe-7s-appVersion',
        label: 'App Version',
        // content: [],
        to:'/appVersion'
    },
    
];

export const EntitlementMismatch = [
    {
        icon: 'pe-7s-entitlementMismatch',
        label: 'Entitlement Mismatch',
        // content: [],
        to:'/entitlementMismatch'
    },
    
];

// export const TotalLauncherCrash = [
//     {
//         icon: 'launcher-crash-icon',
//         label: 'Total Launcher Crash',
//         to:'/total-launcher-crash'
//     },
    
// ];

export const NoSignalCount = [
    {
        icon: 'no-signal-count-icon',
        label: 'No Signal Count',
        to:'/no-signal-count'
    },
    
    ];

    export const ISPDetails = [
        {
            icon: 'isp-icon',
            label: "ISP's Details",
            to:'/ISPs-Details'
        }, 
        ];