import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
    globalAppData:[],

    appsFetchNowControl:[],
    appsFetchNowControlStatus:[],
    appsFetchNowTime:null,
    appsFetchNowTimerFlag:false,
   // calendarData:[]
    webhookFlag:false,
}

const AppsInstalledReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_APPS_REQUEST: return getAppsRequest(state, action);
        case ACTION.GET_APPS_SUCCESS: return getAppsSuccess(state, action);
        case ACTION.GET_APPS_SUCCESS_GLOBAL: return getAppsSuccessGlobal(state, action);
        case ACTION.GET_APPS_FAILURE: return getAppsFailure(state, action);
        
        case ACTION.APPS_FETCH_NOW_CONTROL: return appsFetchNowControl(state, action);
        case ACTION.APPS_FETCH_NOW_STATUS: return appsFetchNowCotrolStatus(state, action);
        case ACTION.APPS_FETCH_NOW_CONTROL_TIMER_FLAG: return appsFetchNowTimerFlag(state, action);

        case ACTION.APPS_FETCH_NOW_RESET: return appsFetchNowReset(state, action);

        case ACTION.APPS_PROCESSED_WEBHOOK_TRIGGERED: return appsProcessedWebhookTriggeredReducer(state, action);

        default: return state;
    }
}

const appsProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getAppsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getAppsSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getAppsSuccessGlobal = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        globalAppData: action.payload,
    });
}
const getAppsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const appsFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        appsFetchNowControl:[],
        appsFetchNowControlStatus:[],
        appsFetchNowTime:null,
        appsFetchNowTimerFlag:false,
    });
}

const appsFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        appsFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const appsFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowAppsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        appsFetchNowControlStatus: action.payload,
    });
}
const appsFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        appsFetchNowTimerFlag: !state.appsFetchNowTimerFlag,
    });
}

export default AppsInstalledReducer;