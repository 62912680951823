import * as ACTION from "./actionTypes";
import { callAccedoAPI } from "../../axios/accedoApi";
import { callAPI } from "../../axios/index";

//Control History Page Actions
const getControlActionRequest = () => {
  return {
    type: ACTION.GET_CONTROL_ACTION_REQUEST,
  };
};

const getControlActionSuccess = (apiData) => {
  return {
    type: ACTION.GET_CONTROL_ACTION_SUCCESS,
    payload: apiData,
  };
};

const getControlActionFailure = (errorMsg) => {
  return {
    type: ACTION.GET_CONTROL_ACTION_FAILURE,
    payload: errorMsg,
  };
};

export const getControlHistoryActionStatus = (
  sid,
  cdsn,
  filterBy,
  filterValue
) => {
  let AppConfigControlResponse = JSON.parse(
    localStorage.getItem("AppConfigControlResponse")
  )?.body?.ConfigData?.data;
  let AppConfigPeriodicResponse = JSON.parse(
    localStorage.getItem("AppConfigPeriodicResponse")
  )?.body?.ConfigData?.data;

  let controlEventsList = [
    ...AppConfigControlResponse,
    ...AppConfigPeriodicResponse,
  ];
  let apiPath;
  apiPath = `/control/status/${sid}?by=CDSN&id=${cdsn}`;
  if (filterBy && filterValue) {
    apiPath = `/control/status/${sid}?by=CDSN&id=${cdsn}&filterBy=${filterBy}&filterValue=${filterValue}`;
  }

  return async function (dispatch) {
    dispatch(getControlActionRequest());
    callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`, "GET")
      .then((response) => {
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}/globalConfig/receptionTimeout`,
          "GET"
        )
          .then((res) => {
            callAccedoAPI(
              `${localStorage.getItem("accedo_url")}/healthCheck`,
              "GET"
            ).then((serverTime) => {
              let temp = JSON.parse(JSON.stringify(response));
              for (let i = 0; i < temp.length; i++) {
                if (temp[i].action === "beacon") {
                  temp[i].action = "entitlement";
                }
                if (temp[i].status === "sent") {
                  if (
                    Date.parse(serverTime?.time) - temp[i].timestamp >
                    (res?.control+1) * 60000
                  ) {
                    temp[i].status = "failed";
                  }
                } else if (temp[i].status === "received") {
                  if ( !temp[i]?.ignoreAcknowledgement &&
                    Date.parse(serverTime?.time) - temp[i].timestamp >
                    controlEventsList?.filter(
                      (events) => events.event == temp[i].action
                    )[0]?.controlEventRequestFailsAfter *
                      60000
                  ) {
                    temp[i].status = "failed";
                  }
                }
              }
              dispatch(getControlActionSuccess(temp));
            });
          })
          .catch((err) => console.log(err.message));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getControlActionFailure(new Date().getTime()));
      });
  };
};

//Reboot STB Actions
const postRebootStbControl = (userToken) => {
  return {
    type: ACTION.POST_CONTROL_EVENT_CONTROL,
    payload: userToken,
  };
};
export const RebootStbTimerFlag = (userToken) => {
  return {
    type: ACTION.CONTROL_EVENT_TIMER_FLAG,
    payload: userToken,
  };
};

export const RebootStbControlStatus = (userToken) => {
  return {
    type: ACTION.GET_POST_CONTROL_EVENT_CONTROL_STATUS,
    payload: userToken,
  };
};

export const resetRebootStbButton = (userToken) => {
  return {
    type: ACTION.RESET_CONTROL_EVENT_BUTTON,
  };
};

const postRebootStbButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_REBOOT_STB_FAILURE,
    payload: errorMsg,
  };
};

export const postRebootStbEvents = (sid, cdsn, payload, controlAction) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `RebootStbSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postRebootStbControl(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(rebootStbTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(RebootStbControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postRebootStbButtonFailure(new Date().getTime()));
      });
  };
};

//factory Reset Actions
const postFactoryResetControlEvent = (userToken) => {
  return {
    type: ACTION.POST_FACTORY_RESET_CONTROL,
    payload: userToken,
  };
};
export const FactoryResetTimerFlag = (userToken) => {
  return {
    type: ACTION.FACTORY_RESET_TIMER_FLAG,
    payload: userToken,
  };
};

export const postFactoryResetEventsControlStatus = (userToken) => {
  return {
    type: ACTION.GET_POST_FACTORY_RESET_CONTROL_STATUS,
    payload: userToken,
  };
};

export const resetFactoryResetButton = (userToken) => {
  return {
    type: ACTION.RESET_FACTORY_RESET_BUTTON,
  };
};

const postFactoryResetButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_FACTORY_RESET_STB_FAILURE,
    payload: errorMsg,
  };
};

export const postFactoryResetEvents = (sid, cdsn, payload, controlAction) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `FactoryResetSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postFactoryResetControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(FactoryResetTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postFactoryResetEventsControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postFactoryResetButtonFailure(new Date().getTime()));
      });
  };
};

//Launcher App Kill Actions
const postAppKillControlEvent = (userToken) => {
  return {
    type: ACTION.POST_APP_KILL_CONTROL,
    payload: userToken,
  };
};
export const AppKillTimerFlag = (userToken) => {
  return {
    type: ACTION.APP_KILL_TIMER_FLAG,
    payload: userToken,
  };
};

export const postAppKillEventsControlStatus = (userToken) => {
  return {
    type: ACTION.GET_POST_APP_KILL_CONTROL_STATUS,
    payload: userToken,
  };
};

export const AppKillResetButton = (userToken) => {
  return {
    type: ACTION.RESET_APP_KILL_BUTTON,
  };
};

const postAppKillButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_APP_KILL_STB_FAILURE,
    payload: errorMsg,
  };
};

export const postAppKillEvents = (sid, cdsn, payload, controlAction) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `AppKillSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postAppKillControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(AppKillTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postAppKillEventsControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postAppKillButtonFailure(new Date().getTime()));
      });
  };
};

//Launcher app clear data Actions
const postAppClearDataControlEvent = (userToken) => {
  return {
    type: ACTION.POST_APP_DATA_CONTROL,
    payload: userToken,
  };
};
export const AppClearDataTimerFlag = (userToken) => {
  return {
    type: ACTION.APP_DATA_TIMER_FLAG,
    payload: userToken,
  };
};

export const postAppClearDataEventsControlStatus = (userToken) => {
  return {
    type: ACTION.GET_POST_APP_DATA_CONTROL_STATUS,
    payload: userToken,
  };
};

export const AppClearDataResetButton = (userToken) => {
  return {
    type: ACTION.RESET_APP_DATA_BUTTON,
  };
};

const postAppClearDataButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_APP_CLEAR_DATA_FAILURE,
    payload: errorMsg,
  };
};

export const postAppClearDataEvents = (sid, cdsn, payload, controlAction) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `AppClearDataSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postAppClearDataControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(AppClearDataTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postAppClearDataEventsControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postAppClearDataButtonFailure(new Date().getTime()));
      });
  };
};

//launcher App clear chache Actions
const postAppClearCacheControlEvent = (userToken) => {
  return {
    type: ACTION.POST_APP_CACHE_CONTROL,
    payload: userToken,
  };
};
export const AppClearCacheTimerFlag = (userToken) => {
  return {
    type: ACTION.APP_CACHE_TIMER_FLAG,
    payload: userToken,
  };
};

export const postAppClearCacheEventsControlStatus = (userToken) => {
  return {
    type: ACTION.GET_POST_APP_CACHE_CONTROL_STATUS,
    payload: userToken,
  };
};

export const AppClearCacheResetButton = (userToken) => {
  return {
    type: ACTION.RESET_APP_CACHE_BUTTON,
  };
};

const postAppClearCacheButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_APP_CLEAR_CACHE_FAILURE,
    payload: errorMsg,
  };
};

export const postAppClearCacheEvents = (sid, cdsn, payload, controlAction) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `AppClearCacheSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postAppClearCacheControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(AppClearCacheTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postAppClearCacheEventsControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postAppClearCacheButtonFailure(new Date().getTime()));
      });
  };
};

//for Capturing Screenshot

const postCaptureScreenShotControlEvent = (userToken) => {
  return {
    type: ACTION.POST_CAPTURE_SCREEN_SHOT_CONTROL,
    payload: userToken,
  };
};
export const CaptureScreenShotTimerFlag = (userToken) => {
  return {
    type: ACTION.CAPTURE_SCREEN_SHOT_TIMER_FLAG,
    payload: userToken,
  };
};

export const postCaptureScreenShotEventControlStatus = (userToken) => {
  return {
    type: ACTION.POST_CAPTURE_SCREEN_SHOT_CONTROL_STATUS,
    payload: userToken,
  };
};

// export const captureScreenShotResetButton = (userToken) => {
//   return {
//     type: ACTION.RESET_CAPTURE_SCREEN_SHOT_BUTTON,
//   };
// };

const postCaptureScreenShotButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_CAPTURE_SCREEN_SHOT_FAILURE,
    payload: errorMsg,
  };
};

export const postCaptureScreenShotEvent = (
  sid,
  cdsn,
  payload,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `CaptureScreenShotSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postCaptureScreenShotControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(AppClearCacheTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postCaptureScreenShotEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postCaptureScreenShotButtonFailure(new Date().getTime()));
      });
  };
};

//For reset parental pin

const postResetParentalPinControlEvent = (userToken) => {
  return {
    type: ACTION.POST_RESET_PARENTAL_PIN_CONTROL,
    payload: userToken,
  };
};
export const ResetParentalPinTimerFlag = (userToken) => {
  return {
    type: ACTION.RESET_PARENTAL_PIN_TIMER_FLAG,
    payload: userToken,
  };
};

export const postResetParentalPinEventControlStatus = (userToken) => {
  return {
    type: ACTION.POST_RESET_PARENTAL_PIN_CONTROL_STATUS,
    payload: userToken,
  };
};

// export const resetParentalPinResetButton = (userToken) => {
//   return {
//     type: ACTION.RESET_RESET_PARENTAL_PIN_BUTTON,
//   };
// };

const postResetParentalPinButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_RESET_PARENTAL_PIN_FAILURE,
    payload: errorMsg,
  };
};

export const postResetParentalPinCodeEvent = (
  sid,
  cdsn,
  payload,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `ResetParentalPinSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postResetParentalPinControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(AppClearCacheTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postResetParentalPinEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postResetParentalPinButtonFailure(new Date().getTime()));
      });
  };
};

//For silent Login

const postSilentLoginControlEvent = (payload) => {
  return {
    type: ACTION.POST_SILENT_LOGIN_CONTROL,
    payload: payload,
  };
};
export const SilentLoginTimerFlag = (payload) => {
  return {
    type: ACTION.SILENT_LOGIN_TIMER_FLAG,
    payload: payload,
  };
};

export const postSilentLoginEventControlStatus = (payload) => {
  return {
    type: ACTION.POST_SILENT_LOGIN_CONTROL_STATUS,
    payload: payload,
  };
};

const postSilentLoginButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_SILENT_LOGIN_FAILURE,
    payload: errorMsg,
  };
};

export const postSilentLoginCodeEvent = (
  sid,
  cdsn,
  payload,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `SilentLoginSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postSilentLoginControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(AppClearCacheTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postSilentLoginEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postSilentLoginButtonFailure(new Date().getTime()));
      });
  };
};

//For Update Pubnub

const postUpdatePubnubControlEvent = (payload) => {
  return {
    type: ACTION.POST_UPDATE_PUBNUB_CONTROL,
    payload: payload,
  };
};
export const UpdatePubnubTimerFlag = (payload) => {
  return {
    type: ACTION.UPDATE_PUBNUB_TIMER_FLAG,
    payload: payload,
  };
};

export const postUpdatePubnubEventControlStatus = (payload) => {
  return {
    type: ACTION.POST_UPDATE_PUBNUB_CONTROL_STATUS,
    payload: payload,
  };
};

const postUpdatePubnubButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_UPDATE_PUBNUB_FAILURE,
    payload: errorMsg,
  };
};

export const postUpdatePubnubEvent = (
  sid,
  cdsn,
  payload,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `UpdatePubnubSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postUpdatePubnubControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(AppClearCacheTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postUpdatePubnubEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postUpdatePubnubButtonFailure(new Date().getTime()));
      });
  };
};

//For Update Pubnub

const postDiagnosisCheckControlEvent = (payload) => {
  return {
    type: ACTION.POST_DIAGNOSIS_CHECK_CONTROL,
    payload: payload,
  };
};
export const DiagnosisCheckTimerFlag = (payload) => {
  return {
    type: ACTION.DIAGNOSIS_CHECK_TIMER_FLAG,
    payload: payload,
  };
};

export const postDiagnosisCheckEventControlStatus = (payload) => {
  return {
    type: ACTION.POST_DIAGNOSIS_CHECK_CONTROL_STATUS,
    payload: payload,
  };
};

const postDiagnosisCheckButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_DIAGNOSIS_CHECK_FAILURE,
    payload: errorMsg,
  };
};

export const postDiagnosisCheckEvent = (
  sid,
  cdsn,
  payload,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      payload
    )
      .then((res) => {
        localStorage.setItem(
          `DiagnosisCheckSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postDiagnosisCheckControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(AppClearCacheTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postDiagnosisCheckEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postDiagnosisCheckButtonFailure(new Date().getTime()));
      });
  };
};

//For Generating Logs
const postAppGenerateLogsControlEvent = (userToken) => {
  return {
    type: ACTION.POST_GENERATE_LOGS_CONTROL,
    payload: userToken,
  };
};
export const GenerateLogsTimerFlag = (userToken) => {
  return {
    type: ACTION.GENERATE_LOGS_TIMER_FLAG,
    payload: userToken,
  };
};

export const postAppGenerateLogsEventsControlStatus = (userToken) => {
  return {
    type: ACTION.GET_POST_APP_GENERATE_LOGS_STATUS,
    payload: userToken,
  };
};

export const GenerateLogsResetButton = (userToken) => {
  return {
    type: ACTION.RESET_GENERATE_LOGS_BUTTON,
  };
};

const postAppGenerateLogsButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_APP_GENERATE_LOGS_FAILURE,
    payload: errorMsg,
  };
};

export const postAppGenerateLogsEvents = (
  sid,
  cdsn,
  DeviceLogspayloadData,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/deviceLogCollection`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      DeviceLogspayloadData
    )
      .then((res) => {
        localStorage.setItem(
          `GenerateLogsSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postAppGenerateLogsControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(GenerateLogsTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postAppGenerateLogsEventsControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postAppGenerateLogsButtonFailure(new Date().getTime()));
      });
  };
};

//For Signal Strength & Quality control request
const postSignalStrengthQualityControlEvent = (userToken) => {
  return {
    type: ACTION.POST_SIGNAL_STRENGTH_QUALITY_CONTROL,
    payload: userToken,
  };
};
export const SignalStrengthQualityTimerFlag = (userToken) => {
  return {
    type: ACTION.SIGNAL_STRENGTH_QUALITY_TIMER_FLAG,
    payload: userToken,
  };
};

export const postSignalStrengthQualityEventControlStatus = (userToken) => {
  return {
    type: ACTION.GET_SIGNAL_STRENGTH_QUALITY_STATUS,
    payload: userToken,
  };
};

// export const SignalStrengthQualityResetButton = (userToken) => {
//   return {
//     type: ACTION.RESET_SIGNAL_STRENGTH_QUALITY_BUTTON,
//   };
// };

const postSignalStrengthQualityButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_SIGNAL_STRENGTH_QUALITY_FAILURE,
    payload: errorMsg,
  };
};

export const postSignalStrengthQualityEvents = (
  sid,
  cdsn,
  DeviceLogspayloadData,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/signalStrengthMonitoring`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      DeviceLogspayloadData
    )
      .then((res) => {
        localStorage.setItem(
          `SignalStrengthQualitySid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postSignalStrengthQualityControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(GenerateLogsTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postSignalStrengthQualityEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postSignalStrengthQualityButtonFailure(new Date().getTime()));
      });
  };
};

//For Play Content control request
const postPlayContentControlEvent = (userToken) => {
  return {
    type: ACTION.POST_PLAY_CONTENT_CONTROL,
    payload: userToken,
  };
};
export const PlayContentTimerFlag = (userToken) => {
  return {
    type: ACTION.PLAY_CONTENT_TIMER_FLAG,
    payload: userToken,
  };
};

export const postPlayContentEventControlStatus = (userToken) => {
  return {
    type: ACTION.GET_PLAY_CONTENT_STATUS,
    payload: userToken,
  };
};

// export const SignalStrengthQualityResetButton = (userToken) => {
//   return {
//     type: ACTION.RESET_SIGNAL_STRENGTH_QUALITY_BUTTON,
//   };
// };

const postPlayContentButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_PLAY_CONTENT_FAILURE,
    payload: errorMsg,
  };
};

export const postPlayContentEvents = (
  sid,
  cdsn,
  DeviceLogspayloadData,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/playContent`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      DeviceLogspayloadData
    )
      .then((res) => {
        localStorage.setItem(
          `PlayContentSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postPlayContentControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(GenerateLogsTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postPlayContentEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        dispatch(postPlayContentButtonFailure(new Date().getTime()));
      });
  };
};

//For Uninstall Apps control request
const postUninstallAppsControlEvent = (userToken) => {
  return {
    type: ACTION.POST_UNINSTALL_APPS_CONTROL,
    payload: userToken,
  };
};
export const UninstallAppsTimerFlag = (userToken) => {
  return {
    type: ACTION.UNINSTALL_APPS_TIMER_FLAG,
    payload: userToken,
  };
};

export const postUninstallAppsEventControlStatus = (userToken) => {
  return {
    type: ACTION.GET_UNINSTALL_APPS_STATUS,
    payload: userToken,
  };
};

// export const SignalStrengthQualityResetButton = (userToken) => {
//   return {
//     type: ACTION.RESET_SIGNAL_STRENGTH_QUALITY_BUTTON,
//   };
// };

const postUninstallAppsButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_UNINSTALL_APPS_FAILURE,
    payload: errorMsg,
  };
};

export const postUninstallAppsEvents = (
  sid,
  cdsn,
  DeviceLogspayloadData,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      DeviceLogspayloadData
    )
      .then((res) => {
        localStorage.setItem(
          `UninstallAppsSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postUninstallAppsControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(GenerateLogsTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postUninstallAppsEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(postUninstallAppsButtonFailure(new Date().getTime()));
      });
  };
};

//For Kill Apps control request
const postKillAppsControlEvent = (payload) => {
  return {
    type: ACTION.POST_KILL_APPS_CONTROL,
    payload: payload,
  };
};
export const KillAppsTimerFlag = (payload) => {
  return {
    type: ACTION.KILL_APPS_TIMER_FLAG,
    payload: payload,
  };
};

export const postKillAppsEventControlStatus = (payload) => {
  return {
    type: ACTION.GET_KILL_APPS_STATUS,
    payload: payload,
  };
};

// export const SignalStrengthQualityResetButton = (userToken) => {
//   return {
//     type: ACTION.RESET_SIGNAL_STRENGTH_QUALITY_BUTTON,
//   };
// };

const postKillAppsButtonFailure = (errorMsg) => {
  return {
    type: ACTION.POST_KILL_APPS_FAILURE,
    payload: errorMsg,
  };
};

export const postKillAppsEvents = (
  sid,
  cdsn,
  DeviceLogspayloadData,
  controlAction
) => {
  const apiPath = `/control/${cdsn}/${controlAction}`;
  let apiPathStatus = `/control/status/${sid}?by=CRID&id=`;
  return async function (dispatch) {
    callAccedoAPI(
      `${localStorage.getItem("accedo_url")}${apiPath}`,
      "POST",
      DeviceLogspayloadData
    )
      .then((res) => {
        localStorage.setItem(
          `KillAppsSid-${sid}Cdsn-${cdsn}InitiateTime`,
          new Date().getTime()
        );
        dispatch(postKillAppsControlEvent(res));
        callAPI("/control", "POST", {
          controlRequestID: res.body.controlRequestID,
          action: res.body.action,
          sid: sid,
          cdsn: cdsn,
        });
        dispatch(getControlHistoryActionStatus(sid, cdsn));
        // setTimeout(() => {
        //   dispatch(GenerateLogsTimerFlag());
        // }, 120000);
        callAccedoAPI(
          `${localStorage.getItem("accedo_url")}${apiPathStatus}${
            res.body.controlRequestID
          }`,
          "GET"
        ).then((response) => {
          dispatch(postKillAppsEventControlStatus(response[0]));
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(postKillAppsButtonFailure(new Date().getTime()));
      });
  };
};
