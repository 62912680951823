import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const CpuTemperatureReducer = (state = initialState, action) => {
    switch(action?.type) {
       
        case ACTION.GET_CPU_TEMPERATURE_REQUEST: return getCpuTemperatureRequest(state, action);
        case ACTION.GET_CPU_TEMPERATURE_SUCCESS: return getCpuTemperatureSuccess(state, action);
        case ACTION.GET_CPU_TEMPERATURE_FAILURE: return getCpuTemperatureFailure(state, action);
        
        default: return state;
    }
}

const getCpuTemperatureRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
        errorMsg:"",
    });
}
const getCpuTemperatureSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
        errorMsg:"",
    });
}
const getCpuTemperatureFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: [],
        errorMsg: action.payload,
    });
}

export default CpuTemperatureReducer;